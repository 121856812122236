import React from 'react'
import Img from 'gatsby-image'
import { useStaticQuery } from 'gatsby'

const Footer = () => {
  const data = useStaticQuery(graphql`
    {
      siteSettings: sanitySiteSettings {
        logo {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        footer_image {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
        phone_number
        main_contact_email
      }
    }
  `)
  return(
    <section id="footer">
      <Img
        fluid={data.siteSettings.footer_image.asset.fluid}
        className="footer-background"
      />
      <img
        src={data.siteSettings.logo.asset.fluid.src}
        alt="Logo"
        style={{
          height: 75,
          marginBottom: 20,
        }}
      />
      <ul className="icons">
        <li>
          <a
            href="https://www.facebook.com/cutaboveagency/"
            className="icon alt fa-facebook"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">Facebook</span>
          </a>
        </li>
        <li>
          <a
            href="https://www.instagram.com/cutaboveagency"
            className="icon alt fa-instagram"
            target="_blank"
            rel="noopener noreferrer"
          >
            <span className="label">Instagram</span>
          </a>
        </li>
        <li>
          <a
            href={`mailto:${data.siteSettings.main_contact_email}`}
            className="icon alt fa-envelope-o"
          >
            <span className="label">Email</span>
          </a>
        </li>
        <li>
          <a href={`tel:${data.siteSettings.phone_number}`} className="icon alt fa-phone">
            <span className="label">Phone</span>
          </a>
        </li>
      </ul>
      <p className="copyright">&copy; Cut Above Agency. All rights reserved.</p>
    </section>
  )

}

export default Footer
