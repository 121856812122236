import * as R from 'ramda'
import { useStaticQuery } from 'gatsby'
import Img from 'gatsby-image'
import React from 'react'
import { Link } from 'gatsby'
import Headroom from 'react-headroom'
import { Drawer } from '@material-ui/core'

//
import config from '../../data/SiteConfig'
import { mapIndexed, notNilOrEmpty } from '../lib/Helpers'

export default (props) => {
  const [open, setOpen] = React.useState(false)

  const toggleDrawer = () => {
    setOpen(!open)
  }

  const data = useStaticQuery(graphql`
    {
      mobileMenu: sanityMobileMenu {
        menu_items {
          slug
          title
        }
      }
      menuItems: sanityMainMenu {
        menu_items {
          slug
          title
          subMenu {
            subMenuSlug
            subMenuTitle
          }
        }
      }
      siteSettings: sanitySiteSettings {
        logo {
          asset {
            fluid {
              ...GatsbySanityImageFluid
            }
          }
        }
      }
    }
  `)

  const { slug } = props
  const { menuItems: {menu_items }} = data
  const { mobileMenu } = data

  return (
    <Headroom>
      <div id="header" className="alt">
        <Link
          to="/"
          style={{
            color: 'white',
            textDecoration: 'none',
          }}
          className="logo"
        >
          <Img
            fluid={data.siteSettings.logo.asset.fluid}
            alt="Cut Above Logo"
          />
        </Link>

        <nav id="nav">
          <ul>
            {mapIndexed( (item, index) => {
              return notNilOrEmpty(item.subMenu) ? (
                <li
                  key={index}
                  className={`${
                    R.any(R.equals(slug))([item.slug])
                      ? 'nav__dropdown current active '
                      : 'nav__dropdown'
                    }
                  `}
                >
                  <span className="icon fa-angle-down">{item.title}</span>
                    <ul className="dropotron level-0 right">
                      {mapIndexed( (subItem, subI) => {
                        return(
                          <li key={subI} className={R.equals(slug, 'about-us') ? 'current' : ''}>
                            {R.startsWith('http', subItem.subMenuSlug) ?
                              <a href={subItem.subMenuSlug}>{subItem.subMenuTitle}</a>
                              :
                              <Link to={subItem.subMenuSlug}>{subItem.subMenuTitle}</Link>
                            }
                          </li>
                        )
                      })(item.subMenu)}
                    </ul>
                  </li>
                )
                : (
                  <li className={R.equals(slug, 'home-page') ? 'current' : ''}>
                    <Link to={item.slug}>{item.title}</Link>
                  </li>
                )
              })(menu_items)
            }
          </ul>
        </nav>

        <Drawer
          anchor="right"
          open={open}
          onClose={toggleDrawer}
        >
          <div
            tabIndex={0}
            role="button"
            onClick={toggleDrawer}
            onKeyDown={toggleDrawer}
            id="navPanel"
            className="offcanvas"
          >
            <nav>
              {notNilOrEmpty(mobileMenu) && mapIndexed( (item, index) => R.startsWith('http', item.slug) ?
                 <a className="link depth-0" href={item.slug}>
                   <span className="indent-0" />
                   {item.title}
                 </a>
               :
                 <Link className="link depth-0" to={item.slug}>
                   <span className="indent-0" />
                   {item.title}
                 </Link>
              )(mobileMenu.menu_items)}
            </nav>
          </div>
        </Drawer>

        <a
          onClick={toggleDrawer}
          href="#navPanel"
          className="navPanelToggle"
        >
          Menu
        </a>
      </div>
    </Headroom>
  )
}
